import React from 'react'

import { ChevronDown } from '@/components/nopk-ui-kit/icons'
import { ChipProps } from '@/interfaces/nopk-ui-kit/index'

export const Chip = ({
  text,
  isActive = false,
  onClickCallback,
  isGreeting = false,
}: ChipProps) => {
  const timeOfDay = () => {
    const hour = new Date().getHours()
    if (hour < 12) {
      return 'morning'
    } else if (hour < 18) {
      return 'afternoon'
    }
    return 'evening'
  }
  const chipText = isGreeting ? `${timeOfDay()} ${text}` : text

  return (
    <span className="relative group">
      <span
        aria-hidden="true"
        className={`flex p-2 items-center  text-sm font-medium rounded-full cursor-pointer px-4 capitalize align-center ${
          isGreeting ? 'justify-between' : 'justify-center w-24'
        } ${
          isActive
            ? ' text-white bg-green-mediumLight'
            : 'text-green bg-gray-light'
        }`}
      >
        {chipText}
        {isGreeting && (
          <ChevronDown color="white" customStyles="h-2 w-2 ml-2" />
        )}
      </span>
      <div className="absolute pt-1.5 left-1/2 transform -translate-x-1/2 top-full group-hover:block hidden">
        <div
          aria-hidden="true"
          onClick={() => onClickCallback()}
          className="absolute z-50 flex items-center justify-center w-48 p-4 text-sm font-medium text-left transform -translate-x-1/2 shadow-md cursor-pointer h-9 text-gray-medium left-1/3"
        >
          Sign Out
          <style jsx>{`
            div {
              position: relative;
              background-color: #ffffff;
              border: solid 1px #f2f2f2;
              border-radius: 4px;
            }
          `}</style>
        </div>
      </div>
    </span>
  )
}
