import {
  ButtonLinkProps,
  ButtonProps,
  ButtonStyleEnum,
} from '@/interfaces/nopk-ui-kit/index'

export const getInnerButtonStyles = (smallText?: boolean) => {
  return ButtonStyleEnum.TERTIARY
    ? `${smallText ? 'text-sm' : 'text-base'} font-medium px-[17px] py-[9px]`
    : `block  px-5 pb-3 pt-3.5 ${
        smallText ? 'text-sm' : 'text-base'
      } font-medium`
}
export const Button = ({
  text,
  onClickCallback,
  isDisabled,
  icon,
  id,
  loading,
  smallText,
  customStyles,
  dark,
}: ButtonProps) => {
  return (
    <button
      id={id}
      disabled={isDisabled}
      className={`__Npt-Cta ${
        icon
          ? isDisabled
            ? '__Npt-Cta-light-disabled'
            : '__Npt-Cta-light'
          : dark
          ? isDisabled
            ? '__Npt-Cta-dark-disabled'
            : '__Npt-Cta-dark '
          : isDisabled
          ? '__Npt-Cta-light-disabled'
          : '__Npt-Cta-light'
      } items-center justify-center flex ${getInnerButtonStyles(
        smallText
      )} ${customStyles}`}
      onClick={() => !isDisabled && onClickCallback()}
    >
      {icon ? (
        <div className="flex flex-row items-center justify-center gap-[9.6px]">
          <span>{icon}</span>
          <p className="text-green whitespace-nowrap">
            {loading ? loading : text}
          </p>
        </div>
      ) : (
        <div className="relative flex flex-row items-center">
          <p className="whitespace-nowrap">{loading ? loading : text}</p>
        </div>
      )}
    </button>
  )
}

export const ButtonLink = ({
  isDisabled,
  text,
  icon,
  onClickCallback,
}: ButtonLinkProps) => {
  const content = (
    <>
      {icon ? (
        <div className="flex flex-row items-center justify-center gap-[9.6px]">
          {icon}
          <p className=" whitespace-nowrap">{text}</p>
        </div>
      ) : (
        <div className="relative flex flex-row items-center">
          <p className="whitespace-nowrap">{text}</p>
        </div>
      )}
    </>
  )

  return (
    <button
      disabled={isDisabled}
      className={`__Npt-Cta ${
        isDisabled ? '__Npt-Cta-link-disabled' : '__Npt-Cta-link '
      } items-center justify-center flex ${getInnerButtonStyles()}`}
      onClick={() => !isDisabled && onClickCallback()}
    >
      {content}
    </button>
  )
}
