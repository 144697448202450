import React from 'react'

import {
  ColumnWidthEnum,
  GridColumnProps,
  GridContainerProps,
} from '@/interfaces/nopk-ui-kit/index'

export const gridColumns = {
  mobile: 'grid-cols-4',
  desktop: 'grid-cols-12',
}

export const gridGutter = {
  mobile: 'gap-[.5rem]',
  desktop: 'gap-[1.5rem]',
}

export const gridMargin = {
  mobile: 'px-[.75rem]',
  desktop: 'px-[2.5rem]',
}

export const GridContainer = ({
  children,
  noMargin = false,
  debugMode = false,
  customStyles = '',
  showContentGrid = false,
}: GridContainerProps) => {
  const baseGridClasses = `max-w-[1620px] min-w-[1024px] grid ${
    showContentGrid ? 'grid-cols-[207px_repeat(10,_1fr)]' : gridColumns.desktop
  } ${gridGutter.desktop}`
  return (
    <div
      className={`${baseGridClasses} ${!noMargin && gridMargin.mobile} ${
        !noMargin && gridMargin.desktop
      } ${debugMode ? '__grid-debugMode' : ''} ${customStyles}`}
    >
      {children}
    </div>
  )
}

export const GridColumn = ({
  columnWidth = ColumnWidthEnum.CONTENT_WIDTH_FULL,
  children,
  debugMode = false,
}: GridColumnProps) => {
  let columnSpan = ''

  if (columnWidth === ColumnWidthEnum.SIDEBAR) {
    columnSpan = 'col-span-1'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_LARGE) {
    columnSpan = 'col-span-10'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_SMALL) {
    columnSpan = 'col-span-4'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_MEDIUM) {
    columnSpan = 'col-span-8'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_NARROW) {
    columnSpan = 'col-span-6 min-w-[525px]'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_MEDIUM_SMALL) {
    columnSpan = 'col-span-7'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_END) {
    columnSpan = 'col-span-11 col-end-13'
  } else if (columnWidth === ColumnWidthEnum.CONTENT_WIDTH_MEDIUM_LARGE) {
    columnSpan = 'col-span-10'
  } else {
    columnSpan = 'col-span-12'
  }

  return (
    <div className={`${debugMode ? 'bg-debug' : ''} ${columnSpan}`}>
      {children}
    </div>
  )
}
