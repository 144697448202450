import { ReactNode } from 'react'

import { NegotiatedRatesProps } from '../accessorials'

export type GridContainerProps = {
  children: ReactNode
  noMargin?: boolean
  debugMode?: boolean
  customStyles?: string
  showContentGrid?: boolean
}

export enum ColumnWidthEnum {
  SIDEBAR = 'SIDEBAR',
  CONTENT_WIDTH_SMALL = 'CONTENT_WIDTH_SMALL',
  CONTENT_WIDTH_FULL = 'CONTENT_WIDTH_FULL',
  CONTENT_WIDTH_LARGE = 'CONTENT_WIDTH_LARGE',
  CONTENT_WIDTH_MEDIUM = 'CONTENT_WIDTH_MEDIUM',
  CONTENT_WIDTH_MEDIUM_LARGE = 'CONTENT_WIDTH_MEDIUM_LARGE',
  CONTENT_WIDTH_MEDIUM_SMALL = 'CONTENT_WIDTH_MEDIUM_SMALL',
  CONTENT_WIDTH_NARROW = 'CONTENT_WIDTH_NARROW',
  CONTENT_WIDTH_END = 'CONTENT_WIDTH_END',
}

export type GridColumnProps = {
  children?: ReactNode
  columnWidth?: ColumnWidthEnum
  debugMode?: boolean
}

export type HeaderProps = {
  isLoading: boolean
  userEmail?: string | null | undefined
  name?: string | null
  navItems: {
    link: string
    href: string
  }[]
}

export type LogoProps = {
  customStyles?: string
}

export enum ButtonStyleEnum {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
}

export type ButtonProps = {
  text: string
  isDisabled?: boolean
  onClickCallback: any
  icon?: JSX.Element
  removeBorder?: boolean
  id?: string
  loading?: string
  smallText?: boolean
  isSecondaryDisabled?: boolean
  customStyles?: string
  dark?: boolean
  removePadding?: boolean
}

export type ButtonLinkProps = {
  text: string
  isDisabled?: boolean
  onClickCallback: any
  icon?: JSX.Element
  smallText?: boolean
}

export type TypographyProps = {
  children: ReactNode
  additionalClassnames?: string
  id?: string
}

export type FormLabelProps = {
  label: string
  id: string
  isRequired?: boolean
}

export enum FormInputTypeEnum {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  DATE = 'date',
  DATETIME_LOCAL = 'datetime-local',
  MONTH = 'month',
  NUMBER = 'number',
  SEARCH = 'search',
  TIME = 'time',
  TEL = 'tel',
  URL = 'url',
  FILE = 'file',
}

export type FormInputProps = {
  label?: string
  id?: string
  inputId?: string
  isRequired?: boolean
  type: FormInputTypeEnum
  placeholderText?: string
  value?: string | number | undefined
  defaultValue?: string | number | undefined
  showChange?: boolean
  isDisabled?: boolean
  onChange: (value: any) => any
  customStyles?: string
  maxValue?: number
  isPrice?: boolean
  isPercentage?: boolean
  isFt?: boolean
  isInch?: boolean
  allowDecimal?: boolean
  negotiatedRates?: NegotiatedRatesProps
  isAbsoluteMin?: boolean
  isMin?: boolean
  isMax?: boolean
  showDefaultValues?: boolean
  setShowDefaultValues?: any
}

export type FormTextareaProps = {
  label: string
  id?: string
  isRequired?: boolean
  placeholderText?: string
  rows?: number
}

export type FormSelectOptionsProps = {
  id?: string | number
  value?: string | number
  displayText?: string | number
  effectiveDate?: string
}

export type FormRadioOptionsProps = {
  value: string
  label: string
  name: string
}

export type FormCheckboxOptionsProps = {
  value: string
  label: string
  isChecked?: boolean
}

export type FormSelectProps = {
  label: string
  id?: string
  inputId?: string
  isRequired?: boolean
  options: FormSelectOptionsProps[]
  value?: string
  startValue?: string | number | readonly string[] | undefined
  startText?: string | number | readonly string[] | undefined
  customStyles?: string
  isDisabled?: boolean
  onChange: (value: any) => any
  addMarginTop?: boolean
  showDefaultOption?: boolean
}

export type FormCheckboxesProps = {
  fieldSetTitle: string
  options: FormCheckboxOptionsProps[]
  isDisabled?: boolean
  onChange: (value: string) => any
  id?: string
  customStyles?: string
  checked?: boolean
}

export type FormRadioButtonsProps = {
  fieldSetTitle: string
  options: FormRadioOptionsProps[]
}

export type SearchWidgetProps = {
  searchQuery: string
  searchResults: CustomerSearchResultProps[]
  onChangeCallback: any
  inputPlaceholder: string
  clearSearch: () => void
  loading: boolean
}

export type CustomerSearchResultProps = {
  name: string
  accountNumber: string
  formattedEffectiveTariff: string | null
}

export type IconParentProps = {
  children: ReactNode
  customStyles?: string
  clickHandler?: any
  id?: string
}

export type IconProps = {
  id?: string
  color?: string
  innerColor?: string
  customStyles?: string
  clickHandler?: any
}

export type ChipProps = {
  text: string
  isActive?: boolean
  isGreeting?: boolean
  onClickCallback: any
}

export type DynamicTableRowProps = {
  label: string
  columnContent: any
}[][]

export enum DynamicTableColumnsEnum {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
}

export type DynamicTableProps = {
  tableColumns: DynamicTableColumnsEnum
  tableRowTemplate: DynamicTableRowProps
  addRowButtonText: string
  tableActionCallback: any
}

export type ExceptionTableProps = {
  tableRowTemplate: DynamicTableRowProps
  addRowButtonText: string
  tableActionCallback: any
  clearRow: (index: number) => void
  disable: boolean
}

export type StaticTableRowProps = {
  key: string | undefined
  value: string | number | undefined
  href?: string
}

export type StaticTableProps = {
  headerTitle?: string
  tableRows: StaticTableRowProps[]
  footer?: string | JSX.Element
}

export type StepNavigationItemProps = {
  label: string
  isComplete: boolean
  isActive: boolean
  isUnlocked: boolean
  url: string
}

export type StepNavigationProps = {
  steps: StepNavigationItemProps[]
  activeURL: string
}

export type AlertProps = {
  link?: string
  text: string
  subtext?: string
  style: AlertStyleEnum
}

export enum AlertStyleEnum {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
}

export type LedgerProps = {
  headline: string
  description?: string
  valuesLayoutStyles?: string
  isCollapsible?: boolean
  dataRows: {
    label: string
    values: string[]
  }[]
}

export type LedgerRowProps = {
  valuesLayoutStyles?: string
  dataRow: {
    label: string
    values: string[]
  }
}

export type TabComponentProps = {
  id?: string
  tabs: string[]
  children: ReactNode
}

export type CheckedToggleProps = {
  id?: string
  enabled: boolean
  isRequired?: boolean
  onChange: (value: any) => any
  disable: boolean
}
