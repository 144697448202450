import Head from 'next/head'
import Script from 'next/script'
import { useSession } from 'next-auth/react'
import React, { ReactNode } from 'react'

import { AccessDenied } from '@/components/auth/access-denied'
import { Header } from '@/components/nopk-ui-kit/header'

type LayoutProps = {
  children: ReactNode
  title: string
}

export const Layout = ({ children, title }: LayoutProps) => {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  const CommonLayoutElements = () => (
    <>
      <Head>
        <title>
          North Park Transportation: Customer Agreements Tool - {title}
        </title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      </Head>

      {/* Google Analytics - GA4 Implementation */}
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
        `}
      </Script>

      <Header
        isLoading={loading}
        userEmail={session?.user?.email}
        name={session?.user?.name}
        navItems={[
          {
            link: 'Learn About C.A.T.',
            href: 'https://sparkly-power-46e.notion.site/Customer-Authentication-Tool-C-A-T-deb6c0d116a64327a8e810c550c97aec',
          },
        ]}
      />
    </>
  )

  if (!session) {
    return (
      <>
        <CommonLayoutElements />
        <main>
          <AccessDenied />
        </main>
      </>
    )
  }

  return (
    <>
      <CommonLayoutElements />
      <main>{children}</main>
    </>
  )
}
