import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { useEffect, useState } from 'react'

import { GridColumn, GridContainer } from '@/components/nopk-ui-kit/grid'

import { getInnerButtonStyles } from '../nopk-ui-kit/buttons'
import { GoogleIcon } from '../nopk-ui-kit/icons'
import { Body, DisplayLevelFive } from '../nopk-ui-kit/typography'

export const AccessDenied = () => {
  const router = useRouter()
  const path = router.asPath
  const [error, setError] = useState('')
  useEffect(() => {
    if (path.includes('error')) {
      setError('Email not found, please try again.')
    }
  }, [path])

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('')
      }, 3000)
    }
  }, [error])

  useEffect
  return (
    <GridContainer>
      <GridColumn>
        <div className="flex flex-col items-center justify-center w-full h-full mt-4">
          <DisplayLevelFive additionalClassnames="my-5">
            Please sign in to continue
          </DisplayLevelFive>
          <Body additionalClassnames="mb-8 font-medium">
            Don&apos;t have a C.A.T. account? Please contact{' '}
            <a
              className="text-sm text-blue-mediumLight hover:underline whitespace-nowrap"
              target="_blank"
              href="mailto:it@nopk.biz"
              rel="noreferrer"
            >
              it@nopk.biz
            </a>
          </Body>
          <a
            href="/api/auth/signin"
            onClick={(e) => {
              e.preventDefault()
              signIn('cognito')
            }}
          >
            <button
              className={`__Npt-Cta __Npt-Cta-dark ${getInnerButtonStyles()}`}
            >
              Sign In
            </button>
          </a>
          <a
            href="/api/auth/signin"
            onClick={(e) => {
              e.preventDefault()
              signIn('google')
            }}
          >
            <button
              className={`__Npt-Cta __Npt-Cta-light ${getInnerButtonStyles()} w-[198px] mt-6 flex items-center `}
            >
              <GoogleIcon customStyles="mr-[4.5px]" />{' '}
              <span className="text-[.95rem] whitespace-no-wrap">
                Sign In With Google
              </span>
            </button>
          </a>
        </div>
        <div>
          {error && (
            <div className="flex flex-col items-center justify-center w-full h-full mt-4">
              <p className="my-5 text-red-mediumLight">{error}</p>
            </div>
          )}
        </div>
      </GridColumn>
    </GridContainer>
  )
}
