import React from 'react'

import { TypographyProps } from '@/interfaces/nopk-ui-kit/index'

export const DisplayLevelOne = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayLevelOne ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayLevelTwo = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayLevelTwo ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayLevelThree = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayLevelThree ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayLevelFour = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayLevelFour ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayLevelFive = ({
  children,
  additionalClassnames,
  id = '',
}: TypographyProps) => (
  <div
    id={id}
    className={`__nopk-typography__DisplayLevelFive ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayLevelSix = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayLevelSix ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const Subtitle = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__Subtitle ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const SubtitleTwo = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__Subtitle2 ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const DisplayStrong = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__DisplayStrong ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const Body = ({
  children,
  additionalClassnames,
  id,
}: TypographyProps) => (
  <div
    id={id}
    className={`__nopk-typography__Body ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const BodyTwo = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__Body2 ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const Caption = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__Caption ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)

export const RichTextArea = ({
  children,
  additionalClassnames,
}: TypographyProps) => (
  <div
    className={`__nopk-typography__RichTextArea ${
      additionalClassnames ? additionalClassnames : ''
    }`}
  >
    {children}
  </div>
)
